import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "./../actions/authActions";
import * as types from "./../types/auth";
import * as service from "./../services/auth";
import { push } from "react-router-redux";
import swal from "sweetalert2";

export function successResponse(response) {
  swal.fire({
    icon: "success",
    timer: 3000,
    text: response?.message ?? "",
  });
}

export function errorResponse(error) {
  if (error?.response?.status == 401) {
    swal.fire({
      icon: "error",
      timer: 3000,
      text: error?.response?.data?.status?.message ?? "",
    });
  } else if (error?.response?.data?.status?.message) {
    swal.fire({
      icon: "error",
      timer: 3000,
      text: error?.response?.data?.status?.message ?? "",
    });
  } else if (error?.response?.data?.message) {
    swal.fire({
      icon: "error",
      timer: 3000,
      text: error?.response?.data?.message ?? "",
    });
  } else {
    swal.fire({
      icon: "error",
      timer: 3000,
      text: error?.message ?? "",
    });
  }
}

function* login(action) {
  const { data, resolve, reject } = action.payload;
  try {
    const result = yield call(service.login, data);
    yield put(actions.loginSuccess(result.data));
    resolve(result);
  } catch (error) {
    errorResponse(error);
    reject(error);
  }
}

function* forgotPassword({ payload }) {
  try {
    const response = yield call(service.forgotPassword, payload);
    successResponse(response);
    yield put(push("/auth/login"));
  } catch (error) {
    errorResponse(error);
  }
}

function* resetPassword({ payload }) {
  try {
    const response = yield call(service.resetPassword, payload);
    successResponse(response);
    yield put(push("/auth/login"));
  } catch (error) {
    errorResponse(error);
  }
}

function* getMe({ token }) {
  try {
    const response = yield call(service.getMe, token);
    yield put(actions.getMeSuccess(response));
  } catch (error) {
    errorResponse(error);
  }
}

export default function* authWatcher() {
  yield takeLatest(types.LOGIN, login);
  yield takeLatest(types.FORGOT_PASSWORD, forgotPassword);
  yield takeLatest(types.RESET_PASSWORD, resetPassword);
  yield takeLatest(types.GET_ME, getMe);
}
