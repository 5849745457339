import React from "react";
import classNames from "classnames";

const TextareaField = ({
  name,
  label,
  register,
  onChange,
  className,
  ...rest
}) => {
  return (
    <div className="mui-textarea-container">
      {register ? (
        <textarea
          id="mui-textarea"
          name={name}
          className={classNames("mui-textarea", className)}
          rows="4"
          placeholder=""
          {...rest}
          {...register(name)}
        />
      ) : (
        <textarea
          id="mui-textarea"
          name={name}
          className={classNames("mui-textarea", className)}
          rows="4"
          placeholder=""
          {...rest}
          onChange={(e) => onChange(e.target.value)}
        />
      )}
      <label className="mui-textarea-label" htmlFor="mui-textarea">
        {label}
      </label>
    </div>
  );
};

export default TextareaField;
