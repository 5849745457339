import React, { Component } from "react";
import { Button } from "reactstrap";
import { BsEyeFill } from "react-icons/bs";
import { Link } from "react-router-dom";

import { LoaderHOC } from "./../../components/Hoc/LoaderHoc";

export const totalMoneySpent = (orderItems) => {
  return orderItems?.reduce((acc, item) => acc + (item?.totalPrice || 0), 0);
};

class UserList extends Component {
  render() {
    const { users, activeVendor, start, end, onContact } = this.props;
    return (
      <tbody>
        {users &&
          users.slice(start, end).map((user) => {
            return (
              <tr key={user?._id}>
                <td>
                  <Link to={`/dashboard/user/${user?._id}`}>
                    <BsEyeFill className="cursor-pointer" size={15} />
                  </Link>
                </td>
                <td className="cursor-pointer" onClick={() => onContact(user?.email)}>{user?.email}</td>
                <td>
                  {user?.firstName} {user?.lastName}
                </td>
                <td>{user?.orderItems?.length}</td>
                <td>${totalMoneySpent(user?.orderItems)}</td>
                <td>
                  {user.activate ? (
                    <Button
                      className="btn btn-sm btn-danger"
                      onClick={() => activeVendor(user._id)}
                    >
                      DeActive
                    </Button>
                  ) : (
                    <Button
                      className="btn btn-sm btn-success"
                      onClick={() => activeVendor(user._id)}
                    >
                      Active
                    </Button>
                  )}
                </td>
              </tr>
            );
          })}
      </tbody>
    );
  }
}

export default LoaderHOC("users", 5)(UserList);
