import React, { Component } from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import Swal from "sweetalert2";
import { Button, Chip } from "@material-ui/core";

import { LoaderHOC } from "./../../components/Hoc/LoaderHoc";
import Modal from "./../../components/Modal/ProductModal";
import DialogModal from "../../components/Modal/DialogModal";
import { allStatus, feeCalculator, OrderStatus } from "../../utilities";

class OrderList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderItem: null,
      showNotes: false,
      showCancelRequestModal: false,
    };
  }
  convertDate = (dt) => {
    const date = new Date(dt);
    return date.toDateString();
  };

  handleInputChange = ({ currentTarget: input }) => {
    let refund = { ...this.state.refund };
    refund[input.name] = input.value;
    this.setState({ refund });
  };

  handleRefund = () => {
    const { orderItem, refund } = this.state;
    const payload = {
      ...refund,
      orderItemId: orderItem?._id,
    };
    this.props.handleRefund(payload);
    this.setState({ modalIsOpen: false });
  };

  handleClickActivity = (orderId) => {
    this.props.history.push(`/dashboard/orders/activity/${orderId}`, orderId);
  };

  onApproveCancelRequest = async () => {
    const { value: comment, isConfirmed } = await Swal.fire({
      html: `<h2 clas="text-dark">Admin reason for approving cancellation</h2><h5>(For internal use only)</h5>`,
      input: "textarea",
      showCancelButton: true,
      cancelButtonColor: "gray",
      confirmButtonColor: "#007BFF",
      reverseButtons: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
      },
    });
    if (isConfirmed) {
      this.props.approveCancelOrder({
        orderItemId: this.state?.orderItem?._id,
        comment,
        token: this?.props?.auth?.token,
      });
    }
    this.setState({ showCancelRequestModal: false });
  };

  render() {
    const { orders, openModal, start, end, config } = this.props;
    
    return (
      <tbody>
        {orders &&
          orders.slice(start, end).map((item) => {
            const addons = item?.addons ?? [];
            const addonTotal = addons?.reduce(
              (total, item) => total + item?.totalPrice,
              0
            );

            const marketingFee = feeCalculator({
              percent: config?.marketingFee,
              total: item?.totalPrice,
              addonTotal,
            });
            const maintenanceFee = feeCalculator({
              percent: config?.maintenanceFee,
              total: item?.totalPrice,
              addonTotal,
            });
            const technologyFee = feeCalculator({
              percent: config?.technologyFee,
              total: item?.totalPrice,
              addonTotal,
            });
            const workerFund = feeCalculator({
              percent: config?.workerFund,
              total: item?.totalPrice,
              addonTotal,
            });

            const orderTotal = (item?.totalPrice + (addonTotal || 0)).toFixed(
              2
            );

            const vendorOrderTotal = (
              (orderTotal || 0) -
              (marketingFee + maintenanceFee + technologyFee + workerFund)
            ).toFixed(2);

            const grossProfit = Math.abs(
              Math.floor(
                orderTotal > parseInt(vendorOrderTotal)
                  ? orderTotal - parseInt(vendorOrderTotal)
                  : parseInt(vendorOrderTotal) - orderTotal
              )
            );

            return (
              <tr key={item._id}>
                <td
                  className="text-blue cursor-pointer"
                  onClick={() => this.handleClickActivity(item?._id)}
                >
                  #{item?.orderNo}
                </td>
                <td>
                  <span style={{ color: "#8e2b14", fontSize: "16px" }}>
                    {item?.user?.firstName || item?.user?.email}
                    <br />
                    {item?.user?.lastName}
                  </span>
                </td>
                <td>{item?.shippingAddressInfo?.address}</td>
                <td>{item?.shippingAddressInfo?.phone}</td>
                <td>
                  <span
                    style={{
                      color: "#fb6340",
                      fontSize: "15px",
                    }}
                  >
                    <b> ${orderTotal}</b> <br />
                  </span>
                  Via PayPal
                </td>
                <td align="center">
                  <span
                    style={{
                      color: "#fb6340",
                      fontSize: "15px",
                    }}
                  >
                    <b> ${grossProfit}</b> <br />
                  </span>
                </td>
                <td>
                  {allStatus.map(
                    (s) =>
                      item?.status === s.status && (
                        <Chip
                          style={{ background: s.color, color: "white" }}
                          label={s?.label}
                        />
                      )
                  )}
                </td>
                <td>
                  <Button
                    onClick={() => {
                      this.setState({ showNotes: true, orderItem: item });
                    }}
                  >
                    Notes
                  </Button>
                </td>
                <td>{this.convertDate(item.createdAt)}</td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light kb-topmenu"
                      role="button"
                      size="sm"
                      color=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      {item?.status == OrderStatus.CANCELLED ? (
                        <DropdownItem className="kb-firstCapital">
                          <span className="text-underline mb-2 ml-2 text-right">
                            Order Cancelled
                          </span>
                        </DropdownItem>
                      ) : item?.cancelRequest?.approved ? (
                        <DropdownItem className="kb-firstCapital">
                          <span className="text-underline mb-2 ml-2 text-right">
                            Cancel Request Approved
                          </span>
                        </DropdownItem>
                      ) : item?.cancelRequest?.reason ? (
                        <DropdownItem className="kb-firstCapital">
                          <span
                            className="text-underline mb-2 ml-2 cursor-pointer text-right"
                            onClick={() => {
                              this.setState({
                                orderItem: item,
                                showCancelRequestModal: true,
                              });
                            }}
                          >
                            Approve Cancel Request
                          </span>
                        </DropdownItem>
                      ) : (
                        <></>
                      )}
                      <DropdownItem
                        className="kb-firstCapital"
                        onClick={() => {
                          openModal(item._id);
                        }}
                      >
                        Details
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            );
          })}
        <Modal
          modalIsOpen={this.state.modalIsOpen}
          closeModal={() => this.setState({ modalIsOpen: false })}
          product={this.props.orderItems}
          image={"refund"}
          handleInputChange={this.handleInputChange}
          handleRefund={this.handleRefund}
          refund={this.state.refund}
          loading={this.props.payment.status.isLoading}
        />
        {this.state.showCancelRequestModal && (
          <DialogModal
            title="Approve cancel request"
            onConfirm={this.onApproveCancelRequest}
            onClose={() =>
              this.setState({ showCancelRequestModal: false, orderItem: null })
            }
          >
            <div>
              <label className="m-0">Reason for Cancelling:</label>
              <p>{this.state?.orderItem?.cancelRequest?.reason}</p>
            </div>
          </DialogModal>
        )}
        {this.state.showNotes && (
          <DialogModal
            yesButtonText="Close"
            onClose={() => this.setState({ showNotes: false, orderItem: null })}
            noButton={false}
            onConfirm={() =>
              this.setState({ showNotes: false, orderItem: null })
            }
          >
            {this.state?.orderItem?.cancelRequest?.reason && (
              <div>
                <label className="m-0">Customer Reason for Cancelling: </label>
                <p className="m-0 mb-2">
                  {this.state?.orderItem?.cancelRequest?.reason}
                </p>
              </div>
            )}
            {this.state?.orderItem?.cancelRequest?.cancelledBy?.user && (
              <>
                <b className="m-0">Order Cancelled By:</b>
                <div>
                  <label className="m-0">Name:</label>
                  <p className="m-0 mb-2">
                    {
                      this.state?.orderItem?.cancelRequest?.cancelledBy?.user
                        ?.firstName
                    }{" "}
                    {
                      this.state?.orderItem?.cancelRequest?.cancelledBy?.user
                        ?.lastName
                    }
                  </p>
                  <label className="m-0">Email:</label>
                  <p className="m-0 mb-2">
                    {
                      this.state?.orderItem?.cancelRequest?.cancelledBy?.user
                        ?.email
                    }
                  </p>
                  <label className="m-0">Comment:</label>
                  <p className="m-0 mb-2">
                    {this.state?.orderItem?.cancelRequest?.cancelledBy?.comment}
                  </p>
                </div>
              </>
            )}
            {this.state?.orderItem?.cancelRequest?.approvedBy?.user && (
              <>
                <b className="m-0">Cancellation Approved By:</b>
                <div>
                  <label className="m-0">Name:</label>
                  <p className="m-0 mb-2">
                    {
                      this.state?.orderItem?.cancelRequest?.approvedBy?.user
                        ?.firstName
                    }{" "}
                    {
                      this.state?.orderItem?.cancelRequest?.approvedBy?.user
                        ?.lastName
                    }
                  </p>
                  <label className="m-0">Email:</label>
                  <p className="m-0 mb-2">
                    {
                      this.state?.orderItem?.cancelRequest?.approvedBy?.user
                        ?.email
                    }
                  </p>
                  {this.state?.orderItem?.cancelRequest?.approvedBy
                    ?.comment && (
                    <>
                      <label className="m-0">Comment:</label>
                      <p className="m-0 mb-2">
                        {
                          this.state?.orderItem?.cancelRequest?.approvedBy
                            ?.comment
                        }
                      </p>
                    </>
                  )}
                </div>
              </>
            )}
          </DialogModal>
        )}
      </tbody>
    );
  }
}

export default LoaderHOC("orders", 7)(OrderList);
