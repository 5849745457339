import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
} from "reactstrap";
import { connect } from "react-redux";
import { TiImage } from "react-icons/ti";

import Header from "../../../components/Headers/DefaultHeader";
import SearchField from "../../../components/SearchFields";
import SelectorField from "../../../components/SearchFields/Selector";
import ShowEntries from "../../../components/SearchFields/ShowEntries";
import ProductSkeleton from "../../../components/Skeleton/Product";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import ProductItem from "./ProductItem";
import CustomPagination from "../../Pagination/pagination";
import { getCategories } from "../../../store/actions/categoryActions";
import * as productActions from "../../../store/actions/productActions";
import * as Const from "../../../utilities";

const Product = (props) => {
  const {
    auth,
    getMyProducts,
    getProducts,
    getCategories,
    categories,
    loading,
    products: productsData,
    deleteProduct,
    trashProduct,
  } = props;
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [restoreDialog, setRestoreDialog] = useState(false);
  const [pagination, setPagination] = useState({ start: 0, end: 10 });

  const skeletons = Array.from({ length: 6 });

  useEffect(() => {
    if (auth?.role === "vendor") {
      getMyProducts(auth?.user);
    } else {
      getProducts(auth?.token, { filter: "1", deleted: true });
    }
  }, []);

  useEffect(() => {
    getCategories(auth?.token, { filter: "1" });
  }, []);

  useEffect(() => {
    setProducts(productsData);
  }, [productsData]);

  const paginationHandler = (start, end) => {
    setPagination({ start, end });
  };

  const closeDialog = () => {
    setDeleteDialog(false);
    setRestoreDialog(false);
  };

  const onConfirmDelete = () => {
    deleteProduct(selectedProduct?._id, auth?.token);
    closeDialog();
  };

  const onConfirmRestore = () => {
    trashProduct(selectedProduct?._id, { deleted: false }, auth?.token);
    closeDialog();
  };

  const nameSearch = (value) => {
    if (value) {
      const filterProducts = products.filter((product) => {
        return product.name.toLowerCase().includes(value?.toLowerCase());
      });
      setProducts(filterProducts);
    } else {
      setProducts(productsData);
    }
  };

  const categorySelect = (value) => {
    if (value) {
      const filter = products.filter((e) => {
        return e?.category && e?.category?._id === value;
      });
      setProducts(filter);
    } else {
      setProducts(productsData);
    }
  };

  const colorSelect = (value) => {
    if (value) {
      const filter = products.filter((e) => e.major_color === value);
      setProducts(filter);
    } else {
      setProducts(productsData);
    }
  };

  const changeSearchText = ({ target: input }) => {
    if (input.value && input.value !== "") {
      let searchText = input.value.toLowerCase();
      const filterProduct = products.filter((el) => {
        return el.name.toLowerCase().includes(searchText);
      });
      setProducts(filterProduct);
    } else {
      setProducts(productsData);
    }
  };

  const handleActions = (data, action) => {
    if (action == "delete") {
      setDeleteDialog(true);
      setSelectedProduct(data);
    } else if (action == "restore") {
      setRestoreDialog(true);
      setSelectedProduct(data);
    }
  };

  const convertDate = (dt) => {
    const date = new Date(dt);
    return date.toDateString();
  };

  return (
    <>
      <Header />
      <Container className="mt--9" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">Products</h3>
              </CardHeader>
              <div className="HeaderWrapper flex-wrap">
                <ShowEntries />
                <SearchField onChange={nameSearch} />
                <SelectorField
                  options={categories?.map((cat) => ({
                    label: cat?.name,
                    value: cat?._id,
                  }))}
                  placeholder="Select Category"
                  onChange={categorySelect}
                />
                <div style={{ width: 150 }}>
                  <SelectorField
                    options={Const.colors}
                    placeholder="Select Color"
                    onChange={colorSelect}
                  />
                </div>
              </div>
              <div className="position-relative">
                <Table
                  className={`align-items-center mainTable table-flush table table-sm`}
                  responsive
                >
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" align="center">
                        <TiImage size={"20px"} />
                      </th>
                      <th scope="col">NAME</th>
                      <th scope="col">SKU</th>
                      <th scope="col">PRICE</th>
                      <th scope="col">DATE</th>
                      <th scope="col">ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      skeletons.map(() => <ProductSkeleton />)
                    ) : products.length === 0 ? (
                      <tr>
                        <td colSpan={12} className="text-center">
                          Record not found
                        </td>
                      </tr>
                    ) : (
                      products
                        .slice(pagination.start, pagination.end)
                        .map((product) => (
                          <ProductItem
                            product={product}
                            handleActions={handleActions}
                          />
                        ))
                    )}
                  </tbody>
                </Table>
              </div>

              <CardFooter className="py-4">
                <nav aria-label="...">
                  <CustomPagination
                    showPerPage={10}
                    paginationHandler={paginationHandler}
                    total={products.length}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      {deleteDialog && (
        <ConfirmationModal
          title={"Are you sure you want to delete?"}
          onClose={() => setDeleteDialog(false)}
          onConfirm={onConfirmDelete}
        />
      )}
      {restoreDialog && (
        <ConfirmationModal
          title={"Are you sure you want to restore?"}
          onClose={() => setRestoreDialog(false)}
          onConfirm={onConfirmRestore}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    products: state.product.products,
    categories: state.category.categories,
    loading: state.product.loading,
    errorMsg: state.product.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: (token, query) => dispatch(getCategories(query, token)),
    getProducts: (token, query) =>
      dispatch(productActions.getProducts(token, query)),
    addView: (id, token) => dispatch(productActions.addView(id, token)),
    getMyProducts: (user) => dispatch(productActions.getMyProducts(user)),
    deleteProduct: (id, token) =>
      dispatch(productActions.deleteProduct(id, token)),
    trashProduct: (id, payload, token) =>
      dispatch(productActions.trashProduct(id, payload, token)),
    addFlag: (id, payload, token) =>
      dispatch(productActions.addFlag(id, payload, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Product);
