import React from "react";
import { Delete } from "@material-ui/icons";
import { Controller } from "react-hook-form";
import { TextField } from "@material-ui/core";
import { FormGroup } from "react-bootstrap";

import TextareaField from "../../components/UI/TextareaField";

const VideoInstance = ({
  index,
  video,
  control,
  handleRemoveVideo,
  errors,
}) => {
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h4>Video #{index + 1}</h4>
        <Delete
          className="text-danger"
          onClick={() => handleRemoveVideo(index)}
        />
      </div>
      <FormGroup className="mb-3">
        <div className="mt-10">
          <label className="font-18">Video Title:</label>
        </div>
        <Controller
          name={`videos[${index}].title`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Title"
              variant="outlined"
              fullWidth
            />
          )}
        />
        {errors?.videos?.[index]?.title?.message && (
          <div className="text-danger">
            {errors?.videos?.[index]?.title?.message}
          </div>
        )}
      </FormGroup>
      <FormGroup className="mb-3">
        <div className="mt-10">
          <label className="font-18">Video Description:</label>
        </div>
        <Controller
          name={`videos[${index}].description`}
          control={control}
          render={({ field }) => (
            <TextareaField {...field} maxRows={4} placeholder="Description" />
          )}
        />
        {errors?.videos?.[index]?.description?.message && (
          <div className="text-danger">
            {errors?.videos?.[index]?.description?.message}
          </div>
        )}
      </FormGroup>
      <FormGroup className="mb-3">
        <div className="mt-10">
          <label className="font-18">Youtube Video URL:</label>
        </div>
        <Controller
          name={`videos[${index}].video`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Video URL"
              variant="outlined"
              fullWidth
            />
          )}
        />
        {errors?.videos?.[index]?.video?.message && (
          <div className="text-danger">
            {errors?.videos?.[index]?.video?.message}
          </div>
        )}
        {video?.video && (
          <iframe
            className="rounded mt-2"
            width={250}
            height={140}
            src={video?.video}
          />
        )}
      </FormGroup>
    </div>
  );
};

export default VideoInstance;
