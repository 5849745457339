import { push } from "react-router-redux";
import { call, put, takeLatest } from "redux-saga/effects";

import * as types from "./../types/article";
import * as service from "./../services/article";
import * as actions from "./../actions/articleActions";
import { errorResponse, successResponse } from "./auth";
import { post } from "../lib/Api";

function* getArticles({ query }) {
  try {
    const result = yield call(service.articles, query);
    yield put(actions.getArticlesSuccess(result.data));
  } catch (error) {
    errorResponse(error);
    yield put(actions.articleFailed());
  }
}

function* publishArticle(action) {
  try {
    const { id, token } = action.payload;
    const result = yield call(service.publishArticle, id, token);
    yield put(actions.publishArticleSuccess(result.data));
    successResponse(result);
  } catch (error) {
    errorResponse(error);
    yield put(actions.articleFailed());
  }
}

function* addArticle({ payload, token }) {
  try {
    const headers = { Authorization: `Bearer ${token}` };
    const result = yield call(() => post("/article", payload, headers));
    yield put(actions.addArticleSuccess(result?.data));
    yield put(push("/dashboard/articles"));
    successResponse(result);
  } catch (error) {
    errorResponse(error);
    yield put(actions.articleFailed());
  }
}

function* deleteArticle(action) {
  try {
    const { id, token } = action.payload;
    const result = yield call(service.deleteArticle, id, token);
    yield put(actions.deleteArticleSuccess(id));
    successResponse(result);
  } catch (error) {
    errorResponse(error);
    yield put(actions.articleFailed());
  }
}

function* updateArticle({ id, payload, token }) {
  try {
    const result = yield call(service.updateArticle, id, payload, token);
    yield put(actions.updateArticleSuccess(result.data));
    yield put(push("/dashboard/articles"));
    successResponse(result);
  } catch (error) {
    errorResponse(error);
    yield put(actions.articleFailed());
  }
}

function* addFlag({ id, payload, token }) {
  try {
    const result = yield call(service.addFlag, id, payload, token);
    yield put(actions.addFlagSuccess(result.data));
    successResponse(result);
  } catch (error) {
    errorResponse(error);
    yield put(actions.articleFailed());
  }
}

function* trashArticle({ id, payload, token }) {
  try {
    const result = yield call(service.trashArticle, id, payload, token);
    yield put(actions.trashArticleSuccess(result.data));
    successResponse(result);
  } catch (error) {
    errorResponse(error);
    yield put(actions.articleFailed());
  }
}

function* deleteComment({ id, token }) {
  try {
    const result = yield call(service.deleteComment, id, token);
    yield put(actions.deleteCommentSuccess(id));
    successResponse(result);
  } catch (error) {
    errorResponse(error);
    yield put(actions.articleFailed());
  }
}

function* getArticle({ slug }) {
  try {
    const result = yield call(service.getArticle, slug);
    yield put(actions.getOneArticleSuccess(result?.data));
  } catch (error) {
    errorResponse(error);
    yield put(actions.articleFailed());
  }
}

export default function* articleWatcher() {
  yield takeLatest(types.GET_ARTICLES, getArticles);
  yield takeLatest(types.ADD_ARTICLE, addArticle);
  yield takeLatest(types.DELETE_ARTICLE, deleteArticle);
  yield takeLatest(types.PUBLISH_ARTICLE, publishArticle);
  yield takeLatest(types.UPDATE_ARTICLE, updateArticle);
  yield takeLatest(types.ADD_FLAG, addFlag);
  yield takeLatest(types.TRASH_ARTICLE, trashArticle);
  yield takeLatest(types.DELETE_COMMENT, deleteComment);
  yield takeLatest(types.GET_ARTICLE, getArticle);
}
