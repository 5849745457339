import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Card, Col, Container, FormGroup, Row } from "react-bootstrap";
import { Add } from "@material-ui/icons";
import { string, array, object, mixed } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import imageCompression from "browser-image-compression";
import { Button, CircularProgress, TextField } from "@material-ui/core";

import DefaultHeader from "../../components/Headers/DefaultHeader";
import Loading from "../../utilities/loading";
import ArticleContent from "./ArticleContent";
import VideoInstance from "./VideoInstance";
import ImageInstance from "./ImageInstance";
import TextareaField from "../../components/UI/TextareaField";
import * as articleActions from "./../../store/actions/articleActions";
import * as categoryActions from "./../../store/actions/categoryActions";
import { errorResponse } from "../../store/sagas/auth";
import { post } from "../../store/lib/Api";
import "../Article/Articles.scss";

const AddArticle = ({ location, history }) => {
  const dispatch = useDispatch();
  const { token, user } = useSelector((state) => state.auth);
  const { articles } = useSelector((state) => state.article);
  const { categories } = useSelector((state) => state.category);
  const [isUploading, setIsUploading] = useState(false);

  const articleData = articles.find((item) => item?._id === location.state);
  const defaultImage = { title: "", description: "", image: null };
  const defaultVideo = { title: "", description: "", video: "" };

  const validationSchema = object().shape({
    name: string().required("Name is required"),
    keywords: string().required("SEO Keywords is required"),
    seoDescription: string().required("SEO Description is required"),
    description: string().required("Description is required"),
    cover: object().required("Cover is required"),
    category: string().required("Category is required"),
    images: array().of(
      object().shape({
        title: string().required("Title is required"),
        description: string().required("Description is required"),
        image: mixed().required("Image is required"),
      })
    ),
    videos: array().of(
      object().shape({
        title: string().required("Title is required"),
        description: string().required("Description is required"),
        video: string().optional(),
      })
    ),
  });

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: articleData?.name ?? "",
      keywords: articleData?.keywords ?? "",
      seoDescription: articleData?.seoDescription ?? "",
      cover: articleData?.cover ?? null,
      description: articleData?.description ?? "",
      category: articleData?.category?._id ?? "",
      images: articleData?.images ?? [defaultImage],
      videos: articleData?.videos ?? [defaultVideo],
    },
  };

  const {
    handleSubmit,
    setValue,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm(formOptions);
  const { append: appendImage, remove: deleteImage } = useFieldArray({
    control,
    name: "images",
  });
  const { append: appendVideo, remove: deleteVideo } = useFieldArray({
    control,
    name: "videos",
  });

  const name = watch("name");
  const description = watch("description");
  const coverImage = watch("cover");
  const images = watch("images");
  const videos = watch("videos");

  useEffect(() => {
    if (token) {
      dispatch(categoryActions.getCategories({ deleted: false }, token));
    }
  }, [token]);

  const onUpload = async (file) => {
    const options = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 800,
      useWebWorker: true,
      fileType: "image/jpeg",
      quality: 0.8,
    };

    const fileType = file.name.split(".").at(-1).toLowerCase();
    const isHeifFile = fileType === "heif" || fileType === "heic";

    try {
      const formData = new FormData();
      let uploadedFile;

      if (isHeifFile) {
        formData.append("media", file);

        setIsUploading(true);
        const result = await post("/users/heif-converter", formData, {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        });
        setIsUploading(false);

        uploadedFile = result?.data?.[0];
      } else {
        const compressedFile = await imageCompression(file, options);
        formData.append("media", compressedFile);

        setIsUploading(true);
        const result = await post("/users/upload-image", formData, {
          "x-api-key": process.env.NEXT_PUBLIC_X_API_KEY,
        });
        setIsUploading(false);

        uploadedFile = result?.data?.[0];
      }

      if (uploadedFile) {
        setValue("cover", uploadedFile);
      }
    } catch (error) {
      errorResponse(error);
    }
  };

  const handleRemoveImage = (index) => {
    deleteImage(index);
  };

  const handleRemoveVideo = (index) => {
    deleteVideo(index);
  };

  function onSubmit(formFields) {
    const {
      name,
      keywords,
      seoDescription,
      description,
      cover,
      category,
      images,
      videos,
    } = formFields;

    const dataObj = {
      name,
      keywords,
      seoDescription,
      description,
      cover,
      category,
      images,
      videos,
      owner: user,
    };

    if (articleData?.name) {
      dispatch(
        articleActions.updateArticle({
          id: articleData._id,
          payload: dataObj,
          token,
        })
      );
    } else {
      dispatch(articleActions.addArticle({ payload: dataObj, token }));
    }
    reset();
  }

  return (
    <>
      <DefaultHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col lg="8" md="12" style={{ margin: "auto" }}>
            <Card className="bg-secondary shadow border-0 position-relative">
              <Card.Body className="px-lg-5 py-lg-5">
                <div className="text-center text-lg font-weight-bold mb-4">
                  Add Article
                </div>
                <Loading
                  width={70}
                  height={70}
                  loading={false}
                  className="kb-loading"
                />

                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormGroup className="mb-3">
                    <div className="mt-10">
                      <label className="font-18">Name:</label>
                    </div>
                    <Controller
                      name="name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          placeholder="Enter Title Here..."
                        />
                      )}
                    />
                    {errors.name?.message && (
                      <div className="text-danger">{errors.name?.message}</div>
                    )}
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <div className="mt-10">
                      <label className="font-18">SEO Keywords:</label>
                    </div>
                    <Controller
                      name="keywords"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          variant="outlined"
                          fullWidth
                          placeholder="Enter Keywords Here..."
                        />
                      )}
                    />
                    {errors.keywords?.message && (
                      <div className="text-danger">
                        {errors.keywords?.message}
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <div className="mt-10">
                      <label className="font-18">SEO Description:</label>
                    </div>
                    <Controller
                      name="seoDescription"
                      control={control}
                      render={({ field }) => (
                        <TextareaField
                          {...field}
                          placeholder="Short Description for SEO Here..."
                        />
                      )}
                    />
                    {errors.seoDescription?.message && (
                      <div className="text-danger">
                        {errors.seoDescription?.message}
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <div className="mt-10">
                      <label className="font-18">Cover image:</label>
                    </div>
                    <Controller
                      name="cover"
                      control={control}
                      render={() => (
                        <input
                          type="file"
                          onChange={(e) => onUpload(e.target.files[0])}
                        />
                      )}
                    />
                    {isUploading ? (
                      <CircularProgress
                        className="mt-2"
                        variant="indeterminate"
                      />
                    ) : coverImage?.url ? (
                      <img
                        className="rounded mt-2"
                        width={150}
                        height={100}
                        src={coverImage?.url}
                      />
                    ) : (
                      errors.cover?.message && (
                        <div className="text-danger">
                          {errors.cover?.message}
                        </div>
                      )
                    )}
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <div className="mt-10">
                      <label className="font-18">Description:</label>
                    </div>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <TextareaField
                          {...field}
                          placeholder="Description and Content Here..."
                        />
                      )}
                    />
                    {errors.description?.message && (
                      <div className="text-danger">
                        {errors.description?.message}
                      </div>
                    )}
                  </FormGroup>
                  <FormGroup className="mb-3">
                    <div className="mt-10">
                      <label className="font-18">Select Category:</label>
                    </div>
                    <Controller
                      name="category"
                      control={control}
                      render={({ field: { value, onChange } }) => (
                        <select
                          className="input form-control category"
                          value={value}
                          placeholder="--- Select cateogry ---"
                          width="100%"
                          onChange={onChange}
                        >
                          <option value="">--- Select cateogry ---</option>
                          {categories.map((category) => (
                            <option key={category._id} value={category._id}>
                              {category.name}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                    {errors.category?.message && (
                      <div className="text-danger">
                        {errors.category?.message}
                      </div>
                    )}
                  </FormGroup>
                  <div className="mt-2">
                    <h3>Images</h3>
                    {images?.map((image, index) => (
                      <div key={index} className="border p-2 mb-2 rounded">
                        <ImageInstance
                          index={index}
                          image={image}
                          watch={watch}
                          control={control}
                          setValue={setValue}
                          errors={errors}
                          handleRemoveImage={handleRemoveImage}
                        />
                      </div>
                    ))}
                    <div className="d-flex justify-content-end mt-2 mb-3">
                      <Button
                        onClick={() => appendImage(defaultImage)}
                        className="bg-primary text-white px-3"
                        startIcon={<Add />}
                      >
                        Add Image
                      </Button>
                    </div>
                  </div>

                  <div className="mt-2">
                    <h3>Videos</h3>
                    {videos?.map((video, index) => (
                      <div key={index} className="border p-2 mb-2 rounded">
                        <VideoInstance
                          index={index}
                          video={video}
                          control={control}
                          errors={errors}
                          handleRemoveVideo={handleRemoveVideo}
                        />
                      </div>
                    ))}
                    <div className="d-flex justify-content-end mt-2 mb-3">
                      <Button
                        onClick={() => appendVideo(defaultVideo)}
                        className="bg-primary text-white px-3"
                        startIcon={<Add />}
                      >
                        Add Video
                      </Button>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between gap-4 mt-4">
                    <Button
                      type="submit"
                      fullWidth
                      className="bg-success text-white"
                    >
                      PUBLISH
                    </Button>
                    <Button
                      type="button"
                      fullWidth
                      className="bg-danger text-white"
                      onClick={(e) => history.push(`/dashboard/articles`)}
                    >
                      CANCEL
                    </Button>
                  </div>
                </form>
                <ArticleContent
                  user={user}
                  directContent={{ cover: coverImage, name, description }}
                  images={images}
                  videos={videos}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddArticle;
