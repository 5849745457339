import * as types from "../types/article";

export const getArticle = (query) => {
  return {
    type: types.GET_ARTICLES,
    query,
  };
};
export const getArticlesSuccess = (articles) => {
  return {
    type: types.GET_ARTICLES_SUCCESS,
    articles,
  };
};

//Add Articles
export const addArticle = (payload) => {
  return {
    type: types.ADD_ARTICLE,
    ...payload,
  };
};
export const addArticleSuccess = (payload) => {
  return {
    type: types.ADD_ARTICLE_SUCCESS,
    payload,
  };
};

export const deleteArticle = (id, token) => {
  return {
    type: types.DELETE_ARTICLE,
    payload: { id, token },
  };
};
export const deleteArticleSuccess = (id) => {
  return {
    type: types.DELETE_ARTICLE_SUCCESS,
    id,
  };
};

export const publishArticle = (id, token) => {
  return {
    type: types.PUBLISH_ARTICLE,
    payload: { id, token },
  };
};
export const publishArticleSuccess = (data) => {
  return {
    type: types.PUBLISH_ARTICLE_SUCCESS,
    data,
  };
};

export const listArticleFailed = (message) => {
  return {
    type: types.LIST_ARTICLE_FAILED,
    message,
  };
};
export const addUpdateArticleFailed = (message) => {
  return {
    type: types.ADD_UPDATE_ARTICLE_FAILED,
    message,
  };
};

export const addView = (id, token) => {
  return {
    type: types.ADD_ARTICLE_VIEW,
    payload: { id, token },
  };
};

export const addViewSuccess = (data) => {
  return {
    type: types.ADD_ARTICLE_VIEW_SUCCESS,
    data,
  };
};

// Update
export const updateArticle = (payload) => {
  return {
    type: types.UPDATE_ARTICLE,
    ...payload,
  };
};
export const updateArticleSuccess = (data) => {
  return {
    type: types.UPDATE_ARTICLE_SUCCESS,
    data,
  };
};

export const addFlag = (id, payload, token) => ({
  type: types.ADD_FLAG,
  id,
  payload,
  token,
});

export const addFlagSuccess = (payload) => ({
  type: types.ADD_FLAG_SUCCESS,
  payload,
});

export const trashArticle = (id, payload, token) => {
  return {
    type: types.TRASH_ARTICLE,
    id,
    payload,
    token,
  };
};

export const trashArticleSuccess = (payload) => {
  return {
    type: types.TRASH_ARTICLE_SUCCESS,
    payload,
  };
};

export const resetArticle = () => ({
  type: types.RESET_ARTICLE,
});

export const deleteComment = (id, token) => ({
  type: types.DELETE_COMMENT,
  id,
  token,
});

export const deleteCommentSuccess = (payload) => ({
  type: types.DELETE_COMMENT_SUCCESS,
  payload,
});

export const getOneArticle = (slug) => ({
  type: types.GET_ARTICLE,
  slug,
});

export const getOneArticleSuccess = (payload) => ({
  type: types.GET_ARTICLE_SUCCESS,
  payload,
});

export const articleFailed = () => ({
  type: types.ARTICLE_FAILED,
});
