import React, { useEffect } from "react";
import { number, object } from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Container,
  TextField,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";

import Header from "./../../components/Headers/DefaultHeader";
import { useDispatch, useSelector } from "react-redux";
import { getConfig, setConfig } from "../../store/actions/settingActions";

const configSchema = object().shape({
  freeDeliveryDistance: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .optional(),
  maxDeliveryDistance: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .optional(),
  marketingFee: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .optional(),
  maintenanceFee: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .optional(),
  technologyFee: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .optional(),
  workerFund: number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .min(1)
    .optional(),
});

const Config = () => {
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { config } = useSelector((state) => state.setting);
  const { formState, control, handleSubmit } = useForm({
    resolver: yupResolver(configSchema),
    defaultValues: config,
  });
  const { errors } = formState;

  useEffect(() => {
    dispatch(getConfig());
  }, []);

  const onSubmit = (data) => {
    dispatch(setConfig(data, token));
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Card className="shadow">
          <CardHeader className="border-0" title="Settings"></CardHeader>
          <CardContent>
            <Container maxWidth="sm" className="ml-0 mr-auto pb-4">
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="d-flex flex-column w-100"
              >
                <Controller
                  name="freeDeliveryDistance"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      variant="outlined"
                      label="Max Free Delivery Distance"
                      error={!!errors?.freeDeliveryDistance?.message}
                      helperText={errors?.freeDeliveryDistance?.message}
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="maxDeliveryDistance"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      className="mt-3"
                      variant="outlined"
                      label="Max Delivery Distance"
                      error={!!errors?.freeDeliveryDistance?.message}
                      helperText={errors?.freeDeliveryDistance?.message}
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="marketingFee"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      className="mt-3"
                      variant="outlined"
                      label="Marketing Fee (%)"
                      error={!!errors?.marketingFee?.message}
                      helperText={errors?.marketingFee?.message}
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="maintenanceFee"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      className="mt-3"
                      variant="outlined"
                      label="Platform Maintenance Fee (%)"
                      error={!!errors?.maintenanceFee?.message}
                      helperText={errors?.maintenanceFee?.message}
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="technologyFee"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      className="mt-3"
                      variant="outlined"
                      label="Technology Fee (%)"
                      error={!!errors?.technologyFee?.message}
                      helperText={errors?.technologyFee?.message}
                      fullWidth
                    />
                  )}
                />
                <Controller
                  name="workerFund"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="number"
                      className="mt-3"
                      variant="outlined"
                      label="Worker Fund (%)"
                      error={!!errors?.workerFund?.message}
                      helperText={errors?.workerFund?.message}
                      fullWidth
                    />
                  )}
                />
                <Button
                  type="submit"
                  className="bg-primary w-fit px-4 mt-3 text-white"
                >
                  Save
                </Button>
              </form>
            </Container>
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default Config;
