import React, { useEffect, useState, useMemo } from "react";
import { connect, useSelector } from "react-redux";
import io from "socket.io-client";

import DefaultHeader from "../Headers/DefaultHeader";
import NotificationItem from "./NotificationItem";
import { AntSwitch } from "../UI/Switch";
import * as userActions from "../../store/actions/userActions";
import { authSelector } from "../../store/reducers";
import { NotificationType } from "./notifications";

export const Index = (props) => {
  const {
    auth,
    notifications,
    getNotifications,
    unreadNotification,
    markNotification,
    markAllNotifications,
  } = props;
  const { fetchingNotification, notificationsQuery } = useSelector(
    (state) => state.user
  );
  const [showAll, setShowAll] = useState(false);
  const [socket, setSocket] = useState(null);
  const { page, hasMoreData } = notificationsQuery;
  const query = window.location.search;
  const type = query?.split("type=").at(-1);
  const notificationTypes = {
    article: [
      NotificationType.NEW_ARTICLE,
      NotificationType.ARTICLE_PUBLISHED,
      NotificationType.ARTICLE_UNPUBLISHED,
    ],
    product: [
      NotificationType.NEW_PRODUCT,
      NotificationType.PRODUCT_APPROVED,
      NotificationType.PRODUCT_CANCELLED,
    ],
    vendor: [
      NotificationType.NEW_VENDOR,
      NotificationType.VENDOR_APPROVED,
      NotificationType.UPDATE_VENDOR,
    ],
    customer: [NotificationType.NEW_CUSTOMER],
    order: [
      NotificationType.ORDER_CANCEL_REQUEST,
      NotificationType.ORDER_CANCEL_REQUEST_APPROVED,
      NotificationType.ORDER_CANCELLED,
    ],
    coupon: [NotificationType.NEW_COUPON, NotificationType.DELETE_COUPON],
    usersWithRole: [NotificationType.NEW_USER_WITH_ROLE],
  }[type];

  // useEffect(() => {
  // setSocket(io(process.env.REACT_APP_API_ENDPOINT));
  // }, []);

  useMemo(() => {
    if (auth?.token) {
      if (showAll) {
        getNotifications(
          auth?.token,
          {
            page: 1,
            limit: 10,
            status: "ALL",
            types: notificationTypes?.join(",") ?? [],
          },
          true
        );
      }
    }
  }, [showAll, type]);

  useMemo(() => {
    if (!showAll) {
      if (auth?.token) {
        getNotifications(
          auth?.token,
          {
            page: 1,
            limit: 10,
            status: "UNREAD",
            types: notificationTypes?.join(",") ?? [],
          },
          true
        );
      }
    }
  }, [showAll, type]);

  useEffect(() => {
    if (socket && auth.user) {
      socket.emit("newUser", auth.user);
    }
  }, [socket, auth.user]);

  // useEffect(() => {
  //   const handleNotification = () => {
  //     if (auth?.token) {
  //       getNotifications(auth?.token);
  //       unreadNotification(auth?.token);
  //     }
  //   };

  //   if (socket) {
  //     socket.on("notification", handleNotification);
  //   }

  //   return () => {
  //     if (socket) {
  //       socket.off("notification", handleNotification);
  //     }
  //   };
  // }, [socket, auth?.token]);

  useEffect(() => {
    const drawer = document.getElementById("notification-list");
    if (drawer) {
      drawer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (drawer) {
        drawer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [fetchingNotification]);

  const handleScroll = (event) => {
    const target = event.target;
    if (
      hasMoreData &&
      target.scrollHeight - target.scrollTop <= target.clientHeight + 5 &&
      !fetchingNotification
    ) {
      if (showAll) {
        getNotifications(
          auth?.token,
          {
            page: page + 1,
            limit: 10,
            status: "ALL",
            types: notificationTypes?.join(",") ?? [],
          },
          false
        );
      } else {
        getNotifications(
          auth?.token,
          {
            page: page + 1,
            limit: 10,
            status: "UNREAD",
            types: notificationTypes?.join(",") ?? [],
          },
          false
        );
      }
    }
  };

  return (
    <div>
      <DefaultHeader headerPadding={true} />
      <div className="notification-container">
        <div className="section-title">Notifications</div>
        <div className="header">
          <div className="switch-wrapper">
            <AntSwitch
              checked={showAll}
              onChange={(e) => setShowAll(e.target.checked)}
              inputProps={{ "aria-label": "ant design" }}
            />
            <div className="text-sm cursor-pointer">Show all</div>
          </div>
          <div
            onClick={() => markAllNotifications(auth?.token)}
            className="custom-link text-sm"
          >
            Mark all as read
          </div>
        </div>
      </div>
      <h2 className="border-t-2 border-gray-200 mb-3" />
      <div
        id="notification-list"
        className="notification-list"
        style={{ overflow: "auto", height: "55vh" }}
      >
        {notifications?.map((notification, index) => (
          <NotificationItem
            key={index}
            token={auth?.token}
            notification={notification}
            markNotification={markNotification}
          />
        ))}
      </div>
      <div style={{ height: "20px", margin: "10px 0" }}>
        {fetchingNotification && <h4>Loading...</h4>}
      </div>
      {!hasMoreData && <p>No more notifications to load</p>}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: authSelector(state),
    notifications: state.user.notifications,
    notificationCount: state.user.notificationCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: (token, query, reset) =>
      dispatch(userActions.getNotifications(token, query, reset)),
    unreadNotification: (token) =>
      dispatch(userActions.unreadNotification(token)),
    markNotification: (token, id) =>
      dispatch(userActions.markNotification(token, id)),
    markAllNotifications: (token) =>
      dispatch(userActions.markAllNotifications(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
