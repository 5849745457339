import React, { useState } from "react";
import { Delete } from "@material-ui/icons";
import { Controller } from "react-hook-form";
import { FormGroup } from "react-bootstrap";
import imageCompression from "browser-image-compression";
import { CircularProgress, TextField } from "@material-ui/core";

import TextareaField from "../../components/UI/TextareaField";
import { errorResponse } from "../../store/sagas/auth";
import { post } from "../../store/lib/Api";

const ImageInstance = ({
  index,
  watch,
  control,
  setValue,
  errors,
  handleRemoveImage,
}) => {
  const [isUploading, setIsUploading] = useState(false);
  const imageData = watch(`images[${index}].image`);

  const onUploadImage = async (file, index) => {
    const options = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 800,
      useWebWorker: true,
      fileType: "image/jpeg",
      quality: 0.8,
    };

    const fileType = file.name.split(".").at(-1).toLowerCase();
    const isHeifFile = fileType === "heif" || fileType === "heic";

    try {
      const formData = new FormData();
      let uploadedFile;

      if (isHeifFile) {
        formData.append("media", file);

        setIsUploading(true);
        const result = await post("/users/heif-converter", formData, {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        });
        setIsUploading(false);

        uploadedFile = result?.data?.[0];
      } else {
        const compressedFile = await imageCompression(file, options);
        formData.append("media", compressedFile);

        setIsUploading(true);
        const result = await post("/users/upload-image", formData, {
          "x-api-key": process.env.NEXT_PUBLIC_X_API_KEY,
        });
        setIsUploading(false);

        uploadedFile = result?.data?.[0];
      }

      if (uploadedFile) {
        setValue(`images[${index}].image`, uploadedFile);
      }
    } catch (error) {
      errorResponse(error);
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h4>Image #{index + 1}</h4>
        <Delete
          className="text-danger"
          onClick={() => handleRemoveImage(index)}
        />
      </div>
      <FormGroup className="mb-3">
        <div className="mt-10">
          <label className="font-18">Image Title:</label>
        </div>
        <Controller
          name={`images[${index}].title`}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder="Title"
              variant="outlined"
              fullWidth
            />
          )}
        />
        {errors?.images?.[index]?.title?.message && (
          <div className="text-danger">
            {errors?.images?.[index]?.title?.message}
          </div>
        )}
      </FormGroup>
      <FormGroup className="mb-3">
        <div className="mt-10">
          <label className="font-18">Image Description:</label>
        </div>
        <Controller
          name={`images[${index}].description`}
          control={control}
          render={({ field }) => (
            <TextareaField maxRows={4} {...field} placeholder="Description" />
          )}
        />
        {errors?.images?.[index]?.description?.message && (
          <div className="text-danger">
            {errors?.images?.[index]?.description?.message}
          </div>
        )}
      </FormGroup>
      <FormGroup className="mb-3">
        <div className="mt-10">
          <label className="font-18">Upload image:</label>
        </div>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            const file = e.target.files?.[0];
            if (file) {
              onUploadImage(file, index);
            }
          }}
        />
        {isUploading ? (
          <CircularProgress className="mt-2" variant="indeterminate" />
        ) : imageData?.url ? (
          <img
            className="rounded mt-2"
            width={150}
            height={100}
            src={imageData?.url}
          />
        ) : (
          errors?.images?.[index]?.image?.message && (
            <div className="text-danger">
              {errors?.images?.[index]?.image?.message}
            </div>
          )
        )}
      </FormGroup>
    </div>
  );
};

export default ImageInstance;
