export const GET_ORDER = "GET_ORDER";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_MY_ORDER = "GET_MY_ORDER";
export const GET_MY_ORDER_SUCCESS = "GET_MY_ORDER_SUCCESS";
export const DELETE_ORDER = "DELETE_ORDER";
export const DELETE_ORDER_SUCCESS = "DELETE_ORDER_SUCCESS";
export const GET_ORDER_ITEMS = "GET_ORDER_ITEMS";
export const GET_ORDER_ITEMS_SUCCESS = "GET_ORDER_ITEMS_SUCCESS";
export const GET_ORDER_ITEMS_FAILURE = "GET_ORDER_ITEMS_FAILURE";
export const GET_ALL_SUBMITTED_HANDSHAKE_REQUIREMENT_DATA = "GET_ALL_SUBMITTED_HANDSHAKE_REQUIREMENT_DATA";
export const GET_ALL_SUBMITTED_HANDSHAKE_REQUIREMENT_DATA_SUCCESS = "GET_ALL_SUBMITTED_HANDSHAKE_REQUIREMENT_DATA_SUCCESS";
export const GET_ALL_SUBMITTED_HANDSHAKE_REQUIREMENT_DATA_FAILURE = "GET_ALL_SUBMITTED_HANDSHAKE_REQUIREMENT_DATA_FAILURE";

export const GET_SINGLE_ORDER = "GET_SINGLE_ORDER";
export const GET_SINGLE_ORDER_SUCCESS = "GET_SINGLE_ORDER_SUCCESS";

export const ORDER_REFUND = "ORDER_REFUND";
export const ORDER_REFUND_SUCCESS = "ORDER_REFUND_SUCCESS";

export const USER_ORDER_REFUND = "USER_ORDER_REFUND";
export const USER_ORDER_REFUND_SUCCESS = "USER_ORDER_REFUND_SUCCESS";

export const GET_VENDOR_ORDERS = "GET_VENDOR_ORDERS";
export const GET_VENDOR_ORDERS_SUCCESS = "GET_VENDOR_ORDERS_SUCCESS";

export const GET_USER_ORDERS = "GET_USER_ORDERS";
export const GET_USER_ORDERS_SUCCESS = "GET_USER_ORDERS_SUCCESS";

export const CANCEL_ORDER_REQUEST = "CANCEL_ORDER_REQUEST";
export const CANCEL_ORDER_REQUEST_SUCCESS = "CANCEL_ORDER_REQUEST_SUCCESS";

export const APPROVE_CANCEL_ORDER_REQUEST = "APPROVE_CANCEL_ORDER_REQUEST";
export const APPROVE_CANCEL_ORDER_REQUEST_SUCCESS = "APPROVE_CANCEL_ORDER_REQUEST_SUCCESS";

export const RESET_ORDER = "RESET_ORDER";

export const GET_REVIEWS = "ORDER/GET_REVIEWS";
export const GET_REVIEWS_SUCCESS = "ORDER/GET_REVIEWS_SUCCESS";

export const CREATE_ORDER_ACTIVITY = "CREATE_ORDER_ACTIVITY";
export const CREATE_ORDER_ACTIVITY_SUCCESS = "CREATE_ORDER_ACTIVITY_SUCCESS";
