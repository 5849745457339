import React, { useEffect, useState } from "react";
import { Button, FormGroup, Form, Row, Col, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { Container } from "react-bootstrap";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import swal from "sweetalert2";

import DefaultHeader from "../../components/Headers/DefaultHeader";
import * as userActions from "../../store/actions/userActions";
import { vendorValidationSchema } from "../../utilities/Schemas/user";
import useDebounce from "../../hooks/useDebounce";
import { get } from "../../store/lib/Api";

const InputField = ({
  type = "text",
  name,
  label,
  register,
  vendor,
  children,
  errors,
  ...rest
}) => {
  return (
    <FormGroup className="d-inline-block w-100 kmb-1">
      <div className="d-flex">
        <TextField
          {...rest}
          type={type}
          name={name}
          label={label}
          variant="outlined"
          size="small"
          fullWidth
          {...register(name)}
        />
        {children}
      </div>
      {errors?.[name]?.message && (
        <div className="text-danger">{errors?.[name]?.message}</div>
      )}
    </FormGroup>
  );
};

const CreateVendor = (props) => {
  const { location, history, auth, vendors, addVendor, updateVendor } = props;

  const vendorData = vendors.find((item) => item._id === location?.state);

  const [suggestions, setSuggestions] = useState([]);

  const { register, handleSubmit, watch, setValue, formState, control } =
    useForm({
      defaultValues: {
        email: vendorData?.email ?? "",
        firstName: vendorData?.firstName ?? "",
        lastName: vendorData?.lastName ?? "",
        storeName: vendorData?.storeName ?? "",
        contact: vendorData?.contact ?? "",
        managerContact: vendorData?.managerContact ?? "",
        storeAddress: vendorData?.storeAddress ?? "",
        w9: vendorData?.w9 ?? "",
      },
      resolver: yupResolver(vendorValidationSchema(!!vendorData)),
    });

  const storeAddress = watch("storeAddress");
  const addressInput = watch("addressInput");
  const debouncedAddressValue = useDebounce(storeAddress, 500);

  const { errors, dirtyFields } = formState;

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (debouncedAddressValue.length < 3) return;
      try {
        const response = await get(
          `/address/places?address=${debouncedAddressValue}`,
          { "x-api-key": process.env.REACT_APP_X_API_KEY }
        );
        const results = response.map((prediction) => prediction.description);
        setSuggestions(results);
      } catch (error) {
        swal.fire({
          title: "Error",
          text: "Error fetching address suggestions",
          icon: "error",
        });
      }
    };

    debouncedAddressValue && dirtyFields.storeAddress && fetchSuggestions();
  }, [debouncedAddressValue]);

  useEffect(() => {
    const fetchAddress = async () => {
      if (!addressInput) return;
      try {
        const response = await get(`/address/place?address=${addressInput}`, {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        });
        const { lat, lng } = response?.[0]?.geometry?.location ?? {};
        setValue("storeAddress", response?.[0]?.formatted_address ?? "");
        setValue("lat", lat);
        setValue("lng", lng);
      } catch (error) {
        swal.fire({
          title: "Error",
          text: "Error fetching address",
          icon: "error",
        });
      }
    };

    addressInput && fetchAddress();
  }, [addressInput]);

  const onSubmit = (formFields) => {
    const formData = new FormData();
    formData.append("firstName", formFields?.firstName);
    formData.append("lastName", formFields?.lastName);
    formData.append("email", String(formFields?.email).toLowerCase());
    !vendorData && formData.append("password", formFields?.password);
    formData.append("storeName", formFields?.storeName);
    formData.append("contact", formFields?.contact);
    formData.append("managerContact", formFields?.managerContact);
    formData.append("storeAddress", formFields?.storeAddress);
    formData.append("lat", formFields?.lat ?? null);
    formData.append("lng", formFields?.lng ?? null);
    formFields?.w9?.length > 0 && formData.append("w9", formFields?.w9[0]);

    if (vendorData) updateVendor(vendorData?._id, formData, auth?.token);
    else addVendor(formData, auth?.token);
    history.push(`/dashboard/vendorstore`, "noRefetch");
  };

  const commonProps = {
    register,
    errors,
    control,
  };

  return (
    <>
      <DefaultHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col lg="8" md="12" style={{ margin: "auto" }}>
            <Card className="bg-secondary shadow border-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-muted mb-4">
                  <h2>{vendorData ? "Update" : "Add"} a vendor</h2>
                </div>
                <div className="position-relative">
                  <div className="new-product">
                    <div className="form-container position-relative">
                      <Form
                        onSubmit={handleSubmit(onSubmit)}
                        className="w-100 d-flex flex-column"
                      >
                        <InputField
                          name="firstName"
                          label="First name"
                          {...commonProps}
                        />
                        <InputField
                          name="lastName"
                          label="Last name"
                          {...commonProps}
                        />
                        <InputField
                          name="email"
                          label="Email"
                          {...commonProps}
                        />
                        {!vendorData && (
                          <InputField
                            type="password"
                            name="password"
                            label="Password"
                            {...commonProps}
                          />
                        )}
                        <InputField
                          name="storeName"
                          label="Store name"
                          {...commonProps}
                        />
                        <InputField
                          name="contact"
                          label="Contact"
                          {...commonProps}
                        />
                        <InputField
                          name="managerContact"
                          label="Manager Contact"
                          {...commonProps}
                        />
                        <Controller
                          control={control}
                          name="storeAddress"
                          render={({ field: { onChange, value } }) => (
                            <Autocomplete
                              value={value}
                              options={suggestions ?? []}
                              fullWidth
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Address"
                                  variant="outlined"
                                  fullWidth
                                  onChange={onChange}
                                />
                              )}
                              onChange={(_, value) => {
                                onChange(value);
                                setValue("addressInput", value);
                              }}
                            />
                          )}
                        />
                        <InputField
                          type="file"
                          InputLabelProps={{ shrink: true }}
                          name="w9"
                          label="W9 Form"
                          className="mt-4"
                          {...commonProps}
                        />
                        <div className="row mt-5">
                          <div className="col-md-6">
                            <Button
                              className="w-100"
                              color="danger"
                              type="button"
                              onClick={() =>
                                history.push(
                                  `/dashboard/vendorstore`,
                                  "noRefetch"
                                )
                              }
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="col-md-6">
                            <Button
                              className="w-100"
                              color={vendorData ? "success" : "primary"}
                              type="submit"
                            >
                              {vendorData ? "Update" : "Add"}
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    vendors: state.user.vendors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addVendor: (payload, token) =>
      dispatch(userActions.addVendor(payload, token)),
    updateVendor: (id, payload, token) =>
      dispatch(userActions.updateVendor(id, payload, token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateVendor);
