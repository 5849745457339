import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import moment from "moment";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

import Comments from "./Comments";
import ConfirmationModal from "../../components/Modal/ConfirmationModal";
import {
  deleteComment,
  getOneArticle,
} from "../../store/actions/articleActions";
import "./Articles.scss";

const ArticleContent = (props) => {
  const {
    auth,
    article,
    match,
    directContent,
    videos,
    images,
    history,
    deleteComment,
    getOneArticle,
  } = props;
  const slug = match.params.slug;
  const content = directContent ?? article;
  const [id, setId] = useState("");

  useEffect(() => {
    if (slug) getOneArticle(slug);
  }, [slug]);

  const onDelete = () => {
    deleteComment(id, auth?.token);
    setId("");
  };

  return (
    <div className={`article-content ${!directContent ? "main" : ""}`}>
      <div className={`${!directContent ? "container" : ""}`}>
        {directContent ? (
          <h1 className="mt-4 mb-4">Article preview</h1>
        ) : (
          <Button className="mb-4" onClick={() => history.goBack()}>
            Go back
          </Button>
        )}
        <div className="name">{content?.name}</div>
        <div className="mt-3">
          <div className="w-full text-lg text-slate-800">
            By{" "}
            {auth
              ? auth?.name
              : `${content?.owner?.firstName} ${content?.owner?.lastName}`}
            {" — "} Published on{" "}
            {moment(content?.updatedAt ?? content?.date).format(
              "MMMM DD, YYYY"
            )}
          </div>
        </div>
        {content?.cover && (
          <div className="w-100 poster-image">
            <img
              height="100%"
              width="100%"
              className="rounded-xl"
              src={content?.cover?.url}
              loading="lazy"
            />
          </div>
        )}
        <div className="poster-description font-light text-lg leading-8 tracking-wider font-[Roboto] mt-4">
          {content?.description}
        </div>
        {(images ?? content?.images)?.map((data, index) => {
          return (
            <div className="mt-5" key={index}>
              <div className="w-100 media-image">
                <img
                  height="100%"
                  width="100%"
                  className="rounded-xl"
                  src={data?.image?.url}
                  alt={data.title}
                  onError={(e) => {
                    e.target.onerror = null;
                  }}
                  loading="lazy"
                />
              </div>
              <div className="mt-12">
                <div className="media-title text-2xl font-medium">
                  {data.title}
                </div>
                <div className="media-description font-light text-lg leading-8 tracking-wider font-[Roboto] mt-4">
                  {data.description}
                </div>
              </div>
            </div>
          );
        })}
        {(videos ?? content?.videos)?.map((data, index) => {
          const videoId = data?.video?.split("/").at(-1);
          return (
            <div className="video-wrapper" key={index}>
              <div className="w-100">
                <div className="article-video">
                  <LiteYouTubeEmbed id={videoId} params="controls=1" />
                </div>
              </div>
              <div className="mt-5">
                <div className="text-2xl font-medium video-title">
                  {data.title}
                </div>
                <div className="video-description font-light text-lg leading-8 tracking-wider font-[Roboto] mt-4">
                  {data.description}
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {content?.comments?.length > 0 && (
        <Comments comments={content?.comments ?? []} setId={setId} />
      )}
      {id && (
        <ConfirmationModal
          title="Are you sure you want to delete this comment?"
          onClose={() => setId("")}
          onConfirm={onDelete}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    article: state.article.article,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteComment: (id, token) => dispatch(deleteComment(id, token)),
    getOneArticle: (slug) => dispatch(getOneArticle(slug)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ArticleContent));
