import React, { useEffect, useState } from "react";
import { Button, FormGroup, Form, InputGroup, Row, Col } from "reactstrap";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import swal from "sweetalert2";
import { Controller, useForm } from "react-hook-form";
import { Close } from "@material-ui/icons";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { yupResolver } from "@hookform/resolvers/yup";

import * as userActions from "../../store/actions/userActions";
import { authSelector } from "./../../store/reducers";
import { userValidationSchema } from "../../utilities/Schemas/user";
import { get } from "../../store/lib/Api";
import useDebounce from "../../hooks/useDebounce";
import { formatAddress } from "./UserListWithRoles";
import { accessFeatures } from "../../utilities";

const animatedComponents = makeAnimated();

const DEFAULT_PASSWORD = "DEFAULT_PASSWORD";

const AddUserModal = ({ userData, onHide, addUser, updateUser, auth }) => {
  const [suggestions, setSuggestions] = useState([]);

  const formOptions = {
    resolver: yupResolver(userValidationSchema(userData ? true : false)),
    defaultValues: {
      firstName: userData?.firstName ?? "",
      lastName: userData?.lastName ?? "",
      email: userData?.email ?? "",
      address: userData?.address ?? "",
      password: userData?.password ? DEFAULT_PASSWORD : "",
      address:
        typeof userData?.address === "string"
          ? userData?.address
          : formatAddress(userData?.address),
      accesses: accessFeatures?.filter((ele) =>
        userData?.accesses?.includes(ele?.value)
      ),
    },
  };

  const { register, handleSubmit, reset, setValue, formState, watch, control } =
    useForm(formOptions);

  const address = watch("address");
  const addressInput = watch("addressInput");
  const debouncedAddressValue = useDebounce(address, 500);

  const { errors, isValid, dirtyFields } = formState;

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (debouncedAddressValue.length < 3) return;
      try {
        const response = await get(
          `/address/places?address=${debouncedAddressValue}`,
          { "x-api-key": process.env.REACT_APP_X_API_KEY }
        );
        const results = response.map((prediction) => prediction.description);
        setSuggestions(results);
      } catch (error) {
        swal.fire({
          title: "Error",
          text: "Error fetching address suggestions",
          icon: "error",
        });
      }
    };

    debouncedAddressValue && dirtyFields.address && fetchSuggestions();
  }, [debouncedAddressValue]);

  useEffect(() => {
    const fetchAddress = async () => {
      if (!addressInput) return;
      try {
        const response = await get(`/address/place?address=${addressInput}`, {
          "x-api-key": process.env.REACT_APP_X_API_KEY,
        });
        setValue("address", response?.[0]?.formatted_address ?? "");
      } catch (error) {
        swal.fire({
          title: "Error",
          text: "Error fetching address",
          icon: "error",
        });
      }
    };

    addressInput && fetchAddress();
  }, [addressInput]);

  const onSubmit = (formFields) => {
    let createObj = {
      firstName: formFields?.firstName,
      lastName: formFields?.lastName,
      email: formFields?.email?.toLowerCase(),
      address: formFields?.address,
      accesses: formFields?.accesses?.map((access) => access?.value),
      roleName: userData?.role ?? undefined,
    };

    if (formFields?.password != DEFAULT_PASSWORD) {
      createObj = {
        ...createObj,
        password: formFields?.password,
      };
    }
    if (isValid) {
      if (userData) {
        updateUser(auth?.token, userData?._id, createObj);
      } else {
        addUser(auth?.token, createObj);
      }
      onHide();
      reset();
    }
  };

  return (
    <Modal show={true} className="addUserModal" onHide={onHide}>
      <div className="close-btn">
        <Close onClick={onHide} className="icon" />
      </div>
      <Row>
        <Col lg="10" md="10" style={{ margin: "auto" }}>
          <div className="text-center text-muted mb-4">
            <h1 className="text-muted">{userData ? "Update" : "Add"} a user</h1>
          </div>
          <div className="position-relative container">
            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <InputGroup>
                  <input
                    className="form-control"
                    placeholder="Firstname"
                    name="firstName"
                    type="text"
                    {...register("firstName")}
                  />
                </InputGroup>
                {errors.firstName?.message && (
                  <div className="text-danger">{errors.firstName?.message}</div>
                )}
              </FormGroup>

              <FormGroup>
                <input
                  className="form-control"
                  placeholder="Lastname"
                  name="lastName"
                  type="text"
                  {...register("lastName")}
                />
                {errors.lastName?.message && (
                  <div className="text-danger">{errors.lastName?.message}</div>
                )}
              </FormGroup>

              <FormGroup>
                <input
                  className="form-control"
                  placeholder="Email"
                  name="email"
                  type="email"
                  {...register("email")}
                />
                {errors.email?.message && (
                  <div className="text-danger">{errors.email?.message}</div>
                )}
              </FormGroup>

              <FormGroup>
                <input
                  className="form-control"
                  placeholder="Password"
                  name="password"
                  type="password"
                  {...register("password")}
                />
                {errors.password?.message && (
                  <div className="text-danger">{errors.password?.message}</div>
                )}
              </FormGroup>

              <FormGroup>
                <Controller
                  control={control}
                  name="address"
                  render={({ field: { onChange, value } }) => (
                    <Autocomplete
                      options={suggestions ?? []}
                      fullWidth
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Address"
                          variant="outlined"
                          size="small"
                          fullWidth
                          onChange={onChange}
                        />
                      )}
                      onChange={(_, value) => {
                        onChange(value);
                        setValue("addressInput", value);
                      }}
                      value={value}
                    />
                  )}
                />
              </FormGroup>

              <FormGroup>
                <Controller
                  name="accesses"
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      isMulti
                      components={animatedComponents}
                      onChange={(selectedOption) => {
                        field.onChange(selectedOption);
                      }}
                      placeholder="Select role"
                      options={accessFeatures}
                    />
                  )}
                />
              </FormGroup>

              <div className="text-center">
                <Button
                  className="my-4"
                  color={userData ? "success" : "primary"}
                  type="submit"
                >
                  {userData ? "Update" : "Add"}
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: authSelector(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addUser: (token, payload) => dispatch(userActions.addUser(token, payload)),
    updateUser: (token, id, payload) =>
      dispatch(userActions.updateUser(token, id, payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
