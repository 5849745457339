import React, { useState } from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import { Flag } from "@material-ui/icons";
import ReactSelect from "react-select";
import { Link } from "react-router-dom";
import { Chip, NativeSelect } from "@material-ui/core";

import { LoaderHOC } from "./../../components/Hoc/LoaderHoc";
import ProductCarousel from "../../components/Carousel/ProductCarousel";
import PreviewModal from "../../components/Modal/PreviewModal";
import BootstrapInput from "../../components/UI/BootstrapInput";
import * as Const from "./../../utilities";

const ProductList = (props) => {
  const {
    products,
    role,
    openModal,
    handleAddFlag,
    handleDelete,
    handleEdit,
    changeStatus,
    start,
    end,
  } = props;

  const [productInfo, setProductInfo] = useState("");

  const onChangeStatus = (product, status) => {
    changeStatus(product, status);
  };

  const visibleData = products.slice(start, end) ?? [];

  return (
    <tbody>
      {visibleData.map((product) => {
        return (
          <tr key={product._id}>
            <td>
              <ProductCarousel product={product} />
            </td>
            <td
              style={{
                minWidth: "250px",
                maxWidth: "250px",
                textWrap: "wrap",
              }}
            >
              <div
                className="text-blue cursor-pointer"
                onClick={() => setProductInfo(product)}
                dangerouslySetInnerHTML={{ __html: product?.name }}
              />
            </td>
            <td
              style={{
                minWidth: "250px",
                maxWidth: "250px",
                textWrap: "wrap",
              }}
            >
              <div
                className="text-black cursor-pointer"
                dangerouslySetInnerHTML={{ __html: product?.description }}
              />
            </td>
            <td>{product.sku}</td>
            <td>
              <div style={{ width: 150 }}>
                <NativeSelect
                  input={<BootstrapInput />}
                  value={Const.productStatusOptions
                    ?.filter((option) => option?.value != "sold")
                    ?.find((option) => {
                      return option?.value === product?.status;
                    })?.value}
                  onChange={(event) => {
                    const val = event.target.value;
                    onChangeStatus(product, val);
                  }}
                >
                  <option value="">Select status</option>
                  {Const.productStatusOptions
                    ?.filter((option) => option?.value != "sold")
                    ?.map((option) => (
                      <option key={option?.label} value={option?.value}>
                        {option?.label}
                      </option>
                    ))}
                </NativeSelect>
              </div>
            </td>
            <td align="center">
              <Flag
                className={product?.flag ? "text-danger" : ""}
                onClick={() => handleAddFlag(product)}
              />
            </td>
            <td style={{ minWidth: "200px" }}>{product?.note}</td>
            <td>
              <Chip
                label={
                  Const.productStatusOptions.find(
                    (option) => option?.value == product?.status
                  )?.label
                }
              />
            </td>
            <td>{product?.stock}</td>
            <td>
              <span style={{ color: product?.inStock === 0 ? "red" : "green" }}>
                {product?.inStock}
              </span>
            </td>
            <td>
              <span
                style={{
                  textDecoration: "line-through",
                  color: "#fb6340",
                }}
              >
                ${product.price} <br />
              </span>
              <span style={{ color: "green" }}>
                ${product.finalPrice} <br />
              </span>
            </td>

            <td>
              <span style={{ color: "gray" }}>
                {" "}
                categories : <br />
              </span>
              <span style={{ color: "green" }}>
                {product.category && product.category.name}
              </span>
            </td>
            <td>{product.views ? product.views : 0}</td>

            <td>{Const.convertDate(product.createdAt)}</td>
            <td>
              {product?.owner?.storeName ? (
                <div>
                  <Link to={`/dashboard/vendorstore`}>
                    {product?.owner?.storeName}
                  </Link>
                  <br />
                  <span>{product?.owner?.email}</span>
                  <br />
                  <span>{product?.owner?.contact}</span>
                  <br />
                  <span>{product?.owner?.storeAddress}</span>
                </div>
              ) : (
                "Stonerem"
              )}
            </td>

            <td className="text-right">
              <UncontrolledDropdown>
                <DropdownToggle
                  className="btn-icon-only text-light kb-topmenu"
                  role="button"
                  size="sm"
                  color=""
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fas fa-ellipsis-v" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  {role === "vendor" && (
                    <DropdownItem
                      className="kb-firstCapital"
                      onClick={() => handleEdit(product._id)}
                    >
                      Edit
                    </DropdownItem>
                  )}
                  <DropdownItem
                    className="kb-firstCapital"
                    onClick={() => handleDelete(product)}
                  >
                    Delete
                  </DropdownItem>
                  <DropdownItem
                    className="kb-firstCapital"
                    onClick={() => openModal(product)}
                  >
                    Details
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </td>
          </tr>
        );
      })}
      {productInfo && (
        <PreviewModal
          productInfo={productInfo}
          onHide={() => setProductInfo("")}
        />
      )}
    </tbody>
  );
};

export default LoaderHOC("products", 10)(ProductList);
