import React, { Component } from "react";
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";

import placeholder from "../../assets/img/Placeholder.png";
import { cloudinaryImage, convertDate, formatDate } from "../../utilities";
import { Flag } from "@material-ui/icons";

class ArticleList extends Component {
  render() {
    const { articles, publishArticle, openModal, handleDelete } = this.props;

    return (
      <tbody>
        {articles &&
          articles.map((article) => {
            const articlePic = article.cover?.url ?? placeholder;
            return (
              <tr key={article._id}>
                <td>
                  <img
                    src={cloudinaryImage({
                      url: articlePic,
                      width: 100,
                      format: true,
                    })}
                    alt=""
                    style={{
                      width: "70px",
                      height: "70px",
                      borderRadius: "4px",
                      backgroundColor: "#fff",
                      border: "2px double #eee",
                      padding: "2px",
                    }}
                  />
                </td>
                <td
                  style={{
                    minWidth: "200px",
                    maxWidth: "200px",
                    whiteSpace: "normal",
                  }}
                >
                  <Link to={`/article/${encodeURIComponent(article?.slug)}`}>
                    {article.name}
                  </Link>
                </td>
                <td align="center">
                  <Flag
                    className={article?.flag ? "text-danger" : ""}
                    onClick={() => this.props.handleAddFlag(article)}
                  />
                </td>
                <td style={{ minWidth: "200px", textAlign: "center" }}>
                  {article?.note}
                </td>
                <td style={{ minWidth: "50px", maxWidth: "50px" }}>
                  {article?.status === "published" ? (
                    <span
                      className="badge badge-success"
                      style={{ fontSize: "14px" }}
                    >
                      Published
                    </span>
                  ) : (
                    <span
                      className="badge badge-warning"
                      style={{ fontSize: "14px" }}
                    >
                      Pending
                    </span>
                  )}
                </td>
                <td style={{ minWidth: "50px", maxWidth: "50px" }}>
                  {article.views}
                </td>
                <td style={{ minWidth: "50px", maxWidth: "50px" }}>
                  {article.likes}
                </td>
                <td
                  style={{
                    minWidth: "100px",
                    maxWidth: "100px",
                    whiteSpace: "normal",
                  }}
                >
                  {article?.category}
                </td>
                <td
                  style={{
                    minWidth: "140px",
                    maxWidth: "140px",
                    whiteSpace: "normal",
                  }}
                >
                  {formatDate(article?.createdAt)}
                </td>
                <td style={{ minWidth: "120px", maxWidth: "120px" }}>
                  {article?.status === "published" ? (
                    <Button onClick={() => publishArticle(article._id)}>
                      Unpublish
                    </Button>
                  ) : (
                    <Button onClick={() => publishArticle(article._id)}>
                      Publish
                    </Button>
                  )}
                </td>
                <td className="text-right">
                  <UncontrolledDropdown>
                    <DropdownToggle
                      className="btn-icon-only text-light kb-topmenu"
                      role="button"
                      size="sm"
                      color=""
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fas fa-ellipsis-v" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-arrow" right>
                      <DropdownItem
                        className="kb-firstCapital"
                        onClick={() => handleDelete(article)}
                      >
                        Delete
                      </DropdownItem>
                      <DropdownItem
                        className="kb-firstCapital"
                        onClick={() => openModal(article)}
                      >
                        Details
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </td>
              </tr>
            );
          })}
      </tbody>
    );
  }
}

export default ArticleList;
